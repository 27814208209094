import React from "react"

import styled from "styled-components"

import Container from "components/container"
import { EmberCard } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 30px;
  > iFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Page = () => (
  <DriveLayout title="Unlocking Exterior Doors">
    <Hero
      title="Unlocking Exterior Doors"
      subtitle="How to unlock the exterior doors of a Yutong TCe12"
    />
    <Container size="medium">
      <EmberCard>
        <VideoWrapper>
          <iframe
            title="Unlocking exterior doors video"
            src="https://youtube.com/embed/n3Ena8CWlTQ"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </VideoWrapper>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
